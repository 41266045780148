import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Overlay from '../Overlay/Overlay'
// css
import './Landing.css'

class Landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false,
      redirectTarget: '',
      typeOfComponent: '',
      param: ''
    }
    this.handeOpenHelp = this.handeOpenHelp.bind(this)
    this.handleSiteRedirect = this.handleSiteRedirect.bind(this)
  }

  handeOpenHelp () {
    this.setState({ typeOfComponent: 'Help' })
  }

  hideOverlay (flag, param) {
    this.setState({ typeOfComponent: '' })
  }

  componentDidMount () {
    if(localStorage.getItem('userInfo') !== "undefined"){
      console.log(localStorage.getItem('userInfo'))
      var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    
    
      if (loginCheck) {
        console.log('loginCheck', loginCheck)
        if (loginCheck.role === 'admin') {
          this.setState({ redirect: true, redirectTarget: '/admin/dashboard' })
        } else {
          this.setState({ redirect: true, redirectTarget: '/teacher/dashboard' })
        }
      }
    }
  }

  handleSiteRedirect () {
    window.location.assign('https://everforwardclub.org/');
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTarget} />
    }
    const componentOverlay = this.state.typeOfComponent === 'Help'
      ? <Overlay typeOfComponent='Help' size='md' hideOverlay={this.hideOverlay.bind(this)} />
      : ''
    return (
      <div id='landing'>
        {componentOverlay}
        <div id='landingImg' className='noselect'>
          <div className='side-by-side w-25 '>
            <div id='sub-div1'>
              <div className='logo-blk'>
                {/* <a href='www.everforwardclub.org'> */}
                  <img src={process.env.PUBLIC_URL + '/assets/images/ic_everforward.png'} className='ever-img hand' alt='logo'  onClick={this.handleSiteRedirect}/>
                {/* </a> */}
                <a href='/'>
                  <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} className='logo-img' alt='logo' />
                </a>
              </div>
              <div className='video-blk'>
                <ReactPlayer className='react-player' url='https://www.youtube.com/watch?v=6_hiav_KDjk' width='300' height='300' />
              </div>
              <div className='create-blk'>
                    <a className='create-btn' href='/drawboard'>
                    <p>CREATE A MASK</p>
                    </a>
                  </div>
              <div className='group'>
                <div className='btn-blk'>
                  <label>Educators, Group Leaders, and Facilitators </label>
                  <a className='signin-btn' href='/auth/teacher/signin'>
                    <p>sign in</p>
                  </a>
                </div>
                <div className='or-blk'>
                  <div className='h-line' />
                  <p>Or</p>
                  <div className='h-line' />
                </div>
                <div className='btn-blk'>
                  <a className='signup-btn' href='/auth/teacher/signup'>
                    <p>sign up</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='side-by-side w-75'>
            <div id='sub-div2'>
              <div className='sub-div2-1'>
                <div className='ourvision-blk'>
                  <h1>Our Vision</h1>
                  <p>To help our young people, adults, and community gain a deeper understanding of how much we have in common, We invite people from around the world to participate in an activity of self-reflection and deep connection.</p>
                </div>
              </div>
              <div className='sub-div2-2'>
                <div className='drawmask-blk'>
                  <div>
                  <h1 className='h1line'>Create A Mask</h1>
                  <h1 className='h2line'>Join The Movement</h1>
                  </div>
                  <div className='draw-blk'>
                    {/* <div className='h1-line1'/> */}
                    <a className='draw-btn' href='/drawboard'>
                    {/* <p>DRAW YOUR MASK</p> */}
                    <img src={process.env.PUBLIC_URL + '/assets/images/DrawButton.png'} className='drawbutton-img' alt='DrawButton' />
                    </a>
                  {/* <div className='h1-line1'/> */}
                  </div>
                </div>
                <div className='viewmask-blk'>
                  <div className='viewtext-blk'>
                  <div>
                    <p className='number'>65,000+</p>
                    <p className='tag'>participants </p>
                  </div>
                  <div>
                    <p className='number'>30+</p>
                    <p className='tag'>countries</p>
                  </div>
                  <div>
                    <p className='number'>700+</p>
                    <p className='tag'>Workshops with Schools <br/> & Organizations</p>
                  </div>
                  </div>
                  <div className='view-btn'>
                    {/* <div className='h1-line2'/> */}
                    <a className='gallery-btn' href='/gallery'>
                    <p>VIEW MASK GALLERY </p>
                  </a>
                  {/* <div className='h1-line2'/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-outer'>
            <div className='footer-inner'>
              <div></div>
              <p>© everforwardclub.org - 2020</p>
              <div className='help-blk' onClick={this.handeOpenHelp}>
                <img src={process.env.PUBLIC_URL + 'assets/images/icons/ic_help.svg'} alt='help' />
                <span>help</span>
              </div>
            </div>
          </div>
          <div className='footer-outer'>
            <div className='footer-inner'>
              <div></div>
              <p>© everforwardclub.org - 2020</p>
              <div className='help-blk' onClick={this.handeOpenHelp}>
                <img src={process.env.PUBLIC_URL + 'assets/images/icons/ic_help.svg'} alt='help' />
                <span>help</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
