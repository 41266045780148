import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
// component
import Toaster from '../Toaster/Toaster'
import agent from '../../api/Api'
// css
import './MyCode.css'

class MyCode extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      teacherCodeList: [],
      codeName: ''
    }
    this.handleGetTeacherCodeList = this.handleGetTeacherCodeList.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleGetTeacherCodeList (email) {
    let url = '/teacher/getAllTeacherCodesById?email=' + email
    agent.APILIST.getMask(url)
      .then(res => {
        if (res.status === 'SUCCESS') {
          var temp = res.codeWithName ? res.codeWithName : {}
          var defaultCode = { Default: res.teacherCode }
          var newTemp = $.extend(defaultCode, temp)
          this.setState({ teacherCodeList: newTemp })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleInputChange (event) {
    const { name, value } = event.target
    let re = /^[a-zA-Z\b]+$/
    if (re.test(value) || value === '') this.setState({ [name]: value })
    if (value.length > 0) {
      $('#' + name).css({ border: '1px solid #ddd' })
    } else {
      $('#' + name).css({ border: '1px solid red' })
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      codeName: this.state.codeName
    }

    let count = 0
    for (var key in param) {
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }

    console.log(count)
    if (count === 1) {
      const url = '/teacher/getNewCode?email=' + this.state.userInfo.email + '&codeName=' + this.state.codeName
      agent.APILIST.getMask(url)
        .then(res => {
          if (res.status === 'SUCCESS') {
            console.log(res.status);
            this.setState({ codeName: '' })
            toast.dismiss()
            toast(<Toaster title='Teacher Code' content={res.message} status='success' />)
            $('#loader').fadeOut()
            this.handleGetTeacherCodeList(this.state.userInfo.email)
          }
        })
        .catch(err => {
          $('#loader').fadeOut()
          console.log(err.response.text)
          let res = JSON.parse(err.response.text)
          toast.dismiss()
          toast(<Toaster title='My code' content={res.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
        })
    } else {
      window.scrollTo(0, 0)
      $('#loader').fadeOut()
    }
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    if (loginCheck) {
      this.setState({ userInfo: loginCheck })
      this.handleGetTeacherCodeList(loginCheck.email)
    }
  }

  render () {
    return (
      <div id='mycode'>
        <div className='mycode-outer-blk noselect'>
          <div className='mycode-blk'>
            <div className='title-blk'>
              <p className='mycode-title'>My Codes</p>
            </div>
            <div className='code-form'>
              <input type='text' name='codeName' id='codeName' maxLength='20' onChange={this.handleInputChange} placeholder='Enter code name' value={this.state.codeName}/>
              <div className='generate-blk' onClick={this.handleSubmit}>
                <p className='generate-btn'>generate code</p>
              </div>
            </div>
            <div className='table-outer'>
              <table>
                <thead>
                  <tr><th>Code Names</th><th>Codes</th></tr>
                </thead>
                <tbody>
                  {Object.keys(this.state.teacherCodeList).map((key, i) => (
                    <tr key={i}><td>{key}</td><td>{this.state.teacherCodeList[key]}</td></tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default MyCode
