import React, { Component} from 'react'
import agent from '../../api/Api'
import { toast } from 'react-toastify'
import Reveal from 'react-reveal/Fade'
import $ from 'jquery'
// component
import Toaster from '../Toaster/Toaster'
// css
import './Subscribe.css'

class Subscribe extends Component {
  constructor (props) {
    super(props)
    this.state = {
      subscribedEmail: '',
      emailError: '1px solid #E3E3E3',
      community: false,
      imageGuid: '',
      uploadurl: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handelSubscribeSubmit = this.handelSubscribeSubmit.bind(this)
    this.handleUploadImg = this.handleUploadImg.bind(this)
    this.handleMaskSubmit = this.handleMaskSubmit.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }

  componentDidMount () {
    // console.log('props', this.props)
  }

  handleCheckboxChange (event) {
    const {name, checked} = event.target
    this.setState({[name]: checked})
    // console.log(event.target.checked, event.target.name)
    if (checked) {
      console.log('checked')
    } else {
      this.setState({ subscribedEmail: '' })
      $('#'+ name).css({'border': '1px solid #E3E3E3'})
      this.setState({community: false})
    }
  }

  handleInputChange (event) {
    const {name, value} = event.target
    this.setState({[name]: value})
    if (value.length > 0) {
      $('#' + name).css({'border': '1px solid #E3E3E3'})

    } else {
      $('#' + name).css({'border': '1px solid red'})
    }
  }

  handleUploadImg (url, param) {
    // console.log('upload', url, param)
    // console.log('this.props.param.imageData', this.props.param.imageData)
    var b64toBlob = require('b64-to-blob')
    var contentType = 'image/png'
    var b64Data = this.props.param.imageData
    if (b64Data) {
      b64Data = b64Data.replace(/^data:image\/(png|jpg);base64,/, '')
      var blob = b64toBlob(b64Data, contentType)
      agent.APILIST.uploadImg(url, blob)
        .then(res => {
          console.log('upload success')
          param.maskGuid = this.state.imageGuid
          delete param.imageData
          this.handleMaskSubmit(param)
        }) 
        .catch(err => {
          console.log(err)
          $('#loader').fadeOut()
          $('#sub-btn').css({'background': '#64A543', 'pointer-events': 'visible'})
          $('#subscribe').css({'cursor': 'default'})
        })
    }
  }

  handleMaskSubmit (param) {
    agent.APILIST.maskSubmit('/mask/submit', param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          toast.dismiss()
          toast(<Toaster title='Mask Status' content={res.message} status='success' />)
          setTimeout(function () { toast.dismiss() }, 3000)
          this.props.hideOverlay('THANKS', param)
        }
      })
      .catch(err => {
        console.log(err)
        $('#loader').fadeOut()
        this.props.hideOverlay('BOARD', param)
        $('#sub-btn').css({'background': '#64A543', 'pointer-events': 'visible'})
        $('#subscribe').css({'cursor': 'default'})
      })
  }

  handelSubscribeSubmit () {
    let param = this.props.param
    let error = true
    if (this.state.community) {
      // console.log(this.state.subscribedEmail)
      if (this.state.subscribedEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        $('#subscribedEmail').css({'border': '1px solid #E3E3E3'})
        // console.log('if')
        param.subscribedEmail = this.state.subscribedEmail
        error = true
      } else {
        $('#subscribedEmail').css({'border': '1px solid red'})
        // console.log('else')
        error = false
      }
    }

    if (error) {
      $('#sub-btn').css({'background': '#ddd', 'pointer-events': 'none'})
      $('#subscribe').css({'cursor': 'progress'})
      agent.APILIST.getPresignedUrl('/mask/signedUrl')
        .then(res => {
          if (res.status === 'SUCCESS') {
            this.setState({uploadurl: res.data.signedUrl, imageGuid: res.data.imageGuid})
            this.handleUploadImg(res.data.signedUrl, param)    
          }
        })
        .catch(err => {
          console.log(err)
          $('#loader').fadeOut()
          $('#sub-btn').css({'background': '#64A543', 'pointer-events': 'visible'})
          $('#subscribe').css({'cursor': 'default'})
        })
    }
  }

  render () {
    return (
      <div id='subscribe'>
        <div className='subscribe-outer noselect'>
          <div className='subscribe-top-outer'>
            <div className='subscribe-top-inner'>
              <p className='title'>Before you submit…</p>   
              <div className='checkbox-label'>
                <div className='round' style={{visibility: 'hidden'}}>
                  <input type='checkbox' />
                  <label />
                </div>
                <div className='label'>
                  <label>Do you wish to be part of this community in the future?</label>
                </div>
              </div>
              <div className='checkbox-label'>
                <div className='round' style={{visibility: 'hidden'}}>
                  <input type='checkbox' />
                  <label />
                </div>
                <div className='checkbox-label label'>
                  <div className='round'>
                    <input type='checkbox' id='community' name='community' onChange={this.handleCheckboxChange} checked={this.state.community} />
                    <label htmlFor='community' />
                  </div>
                  <div className='label'>
                    <label htmlFor='community'>Yes</label>                    
                  </div>
                </div>
              </div>
              {this.state.community
                ? <Reveal effect='fadeInUp'>
                  <div className='checkbox-label'>
                    <div className='round' style={{visibility: 'hidden'}}>
                      <input type='checkbox' />
                      <label />
                    </div>
                    <input type='text' name='subscribedEmail' id='subscribedEmail' maxLength='30' placeholder='Enter your email here' onChange={this.handleInputChange} value={this.state.emailId} />
                  </div>
                </Reveal>
                : ''
              }
              <div className='checkbox-label'>
                <div className='round' style={{visibility: 'hidden'}}>
                  <input type='checkbox' />
                  <label />
                </div>
                <div className='label'>
                  <label className='anonymous-label'>Your masks will remain anonymous</label>
                </div>
              </div>
            </div>
          </div>
          <div className='subscribe-bottom'>
            <div className='confirm-btn' id='sub-btn' onClick={this.handelSubscribeSubmit}>confirm</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Subscribe
