import React, { Component } from 'react'
import Reveal from 'react-reveal/Fade'
import { Redirect } from 'react-router-dom'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import $ from 'jquery'
import { toast } from 'react-toastify'
import { DateRange } from 'react-date-range'
import moment from 'moment'
// component
import Toaster from '../Toaster/Toaster'
import agent from '../../api/Api'
import Header from '../Header/Header'
import { debounce } from 'throttle-debounce'
import Overlay from '../Overlay/Overlay'
// css
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import './AdminDashboard.css'

class AdminDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      phraseActive: '',
      educatorId: 0,
      educatorName: 'Educator',
      educatorFlag: 'all',
      phraseList: [],
      page: 'AD',
      redirect: false,
      redirectTarget: '',
      userInfo: { teacherCode: '', additionalCodes: [] },
      loggedIn: false,
      teacherCodeList: [],
      educatorDetail: [],
      educatorDetailCompleteList: [],
      typeOfComponent: '',
      maskPreviewIndex: '',
      age: '',
      teacherCode: '',
      word: '',
      location: '',
      selectedOptions: [],
      selectedFilterValues: [],
      maskData: [],
      checkedMask: [],
      showDownload: false,
      nextCursor: null,
      isNextPageAvailable: false,
      nextPage: false,
      dateFilter: true,
      filterActive: 'today',
      dateFilterValue: 'dateFilter=today',
      customFilter: false,
      customFilterValue: '',
      dateText: 'Custom',
      showDate: false,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: '#6DD400'
      }
    }
    this.wrapperRef = React.createRef()
    this.handle1GetAllMask = this.handle1GetAllMask.bind(this)
    this.handleGetMask = debounce(500, this.handleGetMask.bind(this))
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this)
    this.handleFilterSelectChange = this.handleFilterSelectChange.bind(this)
    this.handleFilterClear = this.handleFilterClear.bind(this)
    this.handleRemoveSpecificFilter = this.handleRemoveSpecificFilter.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleMaskPreview = this.handleMaskPreview.bind(this)
    this.handleMultipleDownload = this.handleMultipleDownload.bind(this)
    this.handleSingleDownload = this.handleSingleDownload.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    this.handleNextPrev = this.handleNextPrev.bind(this)
    this.handleEducatorDetails = this.handleEducatorDetails.bind(this)
    this.handleOpenEducator = this.handleOpenEducator.bind(this)
    this.handleAllEducator = this.handleAllEducator.bind(this)
    this.handeleDateviceFilter = this.handeleDateviceFilter.bind(this)
    this.handlePhrasesDetails = this.handlePhrasesDetails.bind(this)
    this.handleDateSelection = this.handleDateSelection.bind(this)
    this.handleDateOpen = this.handleDateOpen.bind(this)
    // this.handleClickOutside = this.handleClickOutside.bind(this)
    // this.handleDateRangePraser = this.handleDateRangePraser.bind(this)
  }

  componentDidMount () {
    // console.log('this.props', this.props.match)
    // document.addEventListener('mousedown', this.handleClickOutside)
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('loginCheck', loginCheck)
    if (loginCheck && loginCheck.role === 'admin') {
      $('#loader').fadeIn()
      $('body').css('background', '#373737')
      // get data from remote
      this.handleGetMask('')
      this.handleEducatorDetails()
      this.handlePhrasesDetails('FT')
      // this.handleGetTeacherCodeList()
      this.setState({ userInfo: loginCheck, loggedIn: true })
    } else {
      this.setState({ redirect: true, redirectTarget: '/' })
    }
  }

  componentWillUnmount () {
    $('body').css('background', 'none')
    // document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleGetTeacherCodeList (email) {
    let url = '/teacher/getAllTeacherCodesById?email=' + email
    agent.APILIST.getMask(url)
      .then(res => {
        if (res.status === 'SUCCESS') {
          var temp = res.codeWithName ? res.codeWithName : {}
          var defaultCode = { Default: res.teacherCode }
          var newTemp = $.extend(defaultCode, temp)
          this.setState({ teacherCodeList: newTemp })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handlePhrasesDetails (tagSide) {
    $('#loader').fadeIn()
    this.setState({ phraseActive: tagSide })
    var url = ''
    if (tagSide === 'FT') {
      url = '/admin/getMaskCountForFrontTags'
    } else {
      url = '/admin/getMaskCountForBackTags'
    }
    agent.APILIST.getMask(url)
      .then(res => {
        if (res.status === 'SUCCESS') {
          // var items = res.tagsWithMaskCount.slice(0, 10)
          // console.log('items', items)

          // Create items array
          var phraseitems = Object.keys(res.tagsWithMaskCount).map((key) => {
            return [key, res.tagsWithMaskCount[key]]
          })

          // Sort the array based on the second element
          phraseitems.sort((first, second) => {
            return second[1] - first[1]
          })

          this.setState({ phraseList: phraseitems.slice(0, 10) })

          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err.response.text)
        let res = JSON.parse(err.response.text)
        toast.dismiss()
        toast(<Toaster title='Educator' content={res.message} status='error' />)
        setTimeout(function () { toast.dismiss() }, 3000)
      })
  }

  handleEducatorDetails () {
    $('#loader').fadeIn()
    const url = '/admin/getEducatorsDetails'
    agent.APILIST.getMask(url)
      .then(res => {
        if (res.status === 'SUCCESS') {
          var items = res.data.slice(0, 10)
          // console.log('items', items.length)
          this.setState({ educatorDetailCompleteList: res.data, educatorDetail: items })
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err.response.text)
        let res = JSON.parse(err.response.text)
        toast.dismiss()
        toast(<Toaster title='Educator' content={res.message} status='error' />)
        setTimeout(function () { toast.dismiss() }, 3000)
      })
  }

  handle1GetAllMask (obj) {
    let param = '/admin/adminMaskSearch'
    if (this.state.nextPage) {
      $('#loader').fadeIn()
      param += 'cursor=' + this.state.nextCursor
    }

    agent.APILIST.getMask(param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (this.state.nextPage) {
            const data = this.state.maskData
            const newData = [...data, ...res.data.maskDetails]
            this.setState({ maskData: newData }, () => {
            })
          } else {
            this.setState({ maskData: res.data.maskDetails })
          }
          this.setState({ nextCursor: res.data.nextCursor, isNextPageAvailable: res.data.isNextPageAvailable, nextPage: false })
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err)
      })
  }

  handleGetMask (obj) {
    // console.log('handle obj', obj)
    const { teacherCode } = this.props.match.params
    this.setState({ teacherCode })
    const getMaskUrl = '/admin/adminMaskSearch' // TODO update url with teacher code

    let param = ''
    if (this.state.nextPage) {
      $('#loader').fadeIn()
      param += 'cursor=' + this.state.nextCursor + '&&'
    }
    for (let j = 0; j < obj.length; j++) {
      // console.log('for', obj[j].name)
      if (obj[j].name === 'dateRange') {
        param += 'startDate=' + obj[j].value.split(' - ')[0] + '&&endDate=' + obj[j].value.split(' - ')[1]
      } else param += obj[j].name + '=' + (obj[j].name === 'location' || obj[j].name === 'word' ? obj[j].value.toLowerCase() : obj[j].value)
      if (j < obj.length - 1) {
        param += '&&'
      }
    }
    if (param) {
      param = '?' + param
    }
    // add date filter
    if (this.state.dateFilter) {
      if (!param) param += '?' + this.state.dateFilterValue
      else param += '&&' + this.state.dateFilterValue
    }

    // custom date filter
    if (this.state.customFilter) {
      if (!param) param += '?' + this.state.customFilterValue
      else param += '&&' + this.state.customFilterValue
    }

    console.log('all param', param)
    agent.APILIST.getMask(getMaskUrl + param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (this.state.nextPage) {
            const data = this.state.maskData
            const newData = [...data, ...res.data.maskDetails]
            this.setState({ maskData: newData }, () => {
            })
          } else {
            this.setState({ maskData: res.data.maskDetails })
          }
          this.setState({ nextCursor: res.data.nextCursor, isNextPageAvailable: res.data.isNextPageAvailable, nextPage: false })
          $('#loader').fadeOut()
        }
        $('#loader').fadeOut()
      })
      .catch(err => {
        console.log(err)
        $('#loader').fadeOut()
      })
  }

  handleFilterInputChange (event) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }
    const { name, value } = event.target
    // console.log(name, value)
    const obj = this.state.selectedFilterValues
    const options = this.state.selectedOptions
    let verify = false
    if (name === 'age') {
      if (value.length <= 2 && value[0] !== '0') {
        const re = /^[0-9]+$/
        if (re.test(value) || value === '') {
          this.setState({ [name]: value })
          verify = true
        } else {
          verify = false
        }
      }
    } else if (name === 'location') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value })
        verify = true
      } else {
        verify = false
      }
    } else if (name === 'word') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value })
        verify = true
      } else {
        verify = false
      }
    }

    // console.log('ver', verify)
    if (verify) {
      let done = false
      for (let k = 0; k < obj.length; k++) {
        if (obj[k].name === name) {
          obj[k].value = value
          done = true
        }
      }

      if (!done) {
        obj.push({ name: name, value: value })
        options.push(name)
      }
      if (value.length === 0) {
        options.splice(options.indexOf(name), 1)
        obj.splice(options.indexOf(name), 1)
      }
      this.setState({ selectedFilterValues: obj, selectedOptions: options })
      this.handleGetMask(obj)
      // console.log('input', obj, options)
    }
  }

  handleFilterSelectChange (event) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }
    const { name, value } = event.target
    const options = this.state.selectedOptions
    const obj = this.state.selectedFilterValues

    if (value) {
      if (options.indexOf(name) >= 0) {
        // add selected filter from array
        obj[options.indexOf(name)].value = value
        this.setState({ selectedFilterValues: obj })
        this.handleGetMask(obj)
      } else {
        // add selected option and selected filter to array
        options.push(name)
        obj.push({ name: name, value: value })
        // console.log('option', options)
        this.setState({ selectedFilterValues: obj, selectedOptions: options })
        this.handleGetMask(obj)
      }
    } else {
      const removeOption = this.state.selectedOptions.indexOf(name)
      // remove selected filter from array
      const obj = this.state.selectedFilterValues
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].name === name) {
          obj.splice(i, 1)
        }
      }
      // remove selected option from array
      for (let j = 0; j < options.length; j++) {
        options.splice(removeOption, 1)
      }
      this.setState({ selectedFilterValues: obj, selectedOptions: options })
      // console.log('dropdown', obj, options)
      this.handleGetMask(obj)
    }
  }

  handeleDateviceFilter (value) {
    $('.gallery-dashboard-filter-cus').removeClass('active')
    var selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#6DD400'
    }
    this.setState({
      filterActive: value,
      customFilter: false,
      customFilterValue: '',
      dateText: 'Custom',
      selectionRange: selectionRange,
      dateFilter: true,
      dateFilterValue: 'dateFilter=' + value
    })
    const obj = this.state.selectedFilterValues
    this.handleGetMask(obj)
  }

  handleDateOpen (event) {
    this.setState({ showDate: !this.state.showDate })
  }

  handleDateSelection (ranges) {
    // console.log(ranges)
    var selectionRange = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: 'selection',
      color: '#6DD400'
    }
    const sDate = moment(new Date(ranges.selection.startDate)).format('MMM DD, YYYY').toUpperCase()
    const eDate = moment(new Date(ranges.selection.endDate)).format('MMM DD, YYYY').toUpperCase()
    const sDateMs = moment(sDate).valueOf()
    const eDateMs = moment(eDate).valueOf()
    if (sDateMs < eDateMs) {
      // add active color
      $('.gallery-dashboard-filter-cus').addClass('active')
      // remove active color
      this.setState({ selectionRange: selectionRange, dateText: sDate + ' - ' + eDate, filterActive: null, dateFilter: false, customFilter: true, customFilterValue: 'startDate=' + sDateMs + '&&endDate=' + eDateMs, showDate: false })
      const obj = this.state.selectedFilterValues
      this.handleGetMask(obj)
    }
  }

  handleCheckboxChange (event) {
    const { checked } = event.target
    const temp = this.state.checkedMask
    // console.log(event.target.dataset.id, event.target.dataset.index, checked)
    const index = event.target.dataset.index

    if (checked) {
      temp.push(this.state.maskData[index])
      this.setState({ checkedMask: temp })
    } else {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].maskId === parseInt(event.target.dataset.id)) {
          temp.splice(i, 1)
          this.setState({ checkedMask: temp })
        }
      }
    }

    if (temp.length > 0) {
      this.setState({ showDownload: true })
    } else {
      this.setState({ showDownload: false })
    }
    // console.log('temp', temp)
  }

  handleFilterClear () {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }

    var selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#6DD400'
    }

    this.setState({
      selectedFilterValues: [],
      selectedOptions: [],
      word: '',
      age: '',
      location: '',
      dateFilter: '',
      dateRange: '',
      filterActive: null,
      dateText: 'Custom',
      selectionRange: selectionRange
    })
    const selectTags = document.getElementsByTagName('select')
    for (var i = 0; i < selectTags.length; i++) {
      selectTags[i].selectedIndex = 0
    }
    // remove active color
    $('.gallery-dashboard-filter-cus').removeClass('active')
    this.handleGetMask('')
  }

  handleRemoveSpecificFilter (name) {
    // console.log('specific name', name)
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }

    const options = this.state.selectedOptions
    const obj = this.state.selectedFilterValues

    // remove selected filter from array
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].name === name) {
        obj.splice(i, 1)
      }
    }

    $('#' + name).val('')
    this.setState({ [name]: '' })

    // remove selected option from array
    const removeOption = options.indexOf(name)
    options.splice(removeOption, 1)
    if (name === 'dateRange') {
      var selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: '#6DD400'
      }
      this.setState({
        dateRange: '',
        dateText: 'Custom',
        selectionRange: selectionRange
      })
      // remove active color
      $('.gallery-dashboard-filter-cus').removeClass('active')
    }
    this.setState({ selectedFilterValues: obj, selectedOptions: options })
    // console.log('spedific remove', obj, options)
    this.handleGetMask(obj)
  }

  handleNextPrev (flag) {
    const obj = this.state.selectedFilterValues
    if (flag === 'N' && this.state.isNextPageAvailable) {
      this.setState({ nextPage: true }, () => {
        this.handleGetMask(obj)
      })
    }
  }

  handleMaskPreview (index) {
    this.setState({ typeOfComponent: 'MaskPreview', maskPreviewIndex: index })
  }

  handleAllEducator () {
    this.setState({ typeOfComponent: 'EducatorDetail', educatorFlag: 'all' })
  }

  handleOpenEducator (id, name) {
    this.setState({ typeOfComponent: 'EducatorDetail', educatorId: id, educatorName: name, educatorFlag: 'single' })
  }

  hideOverlay () {
    this.setState({ typeOfComponent: '' })
    $('#loader').fadeOut()
  }

  handleSingleDownload (guid) {
    const url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + guid + '.png'
    saveAs(url, guid + '.png')
  }

  handleMultipleDownload () {
    var zip = new JSZip()
    var count = 0
    var pages = this.state.checkedMask

    pages.forEach(file => {
      fetch('https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + file.maskGuid + '.png', { method: 'GET' }).then(response => response.blob())
        .then(response => {
          // console.log(response)
          zip.file(file.maskGuid + '.png', response, { binary: true })
          ++count
          if (count === pages.length) {
            zip.generateAsync({ type: 'blob' })
              .then((content) => {
                saveAs(content, 'maskDownload.zip')
                $('.checkMask').prop('checked', false)
                this.setState({ showDownload: false, checkedMask: [] })
                this.handleFilterClear()
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  // handleClickOutside (event) {
  //   if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
  //     // alert('You clicked outside of me!')
  //     console.log('outside')
  //     if (this.state.showDate) {
  //       console.log('outside false')
  //       this.setState({ showDate: false })
  //     }
  //   }
  // }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTarget} />
    }
    const componentOverlay = this.state.typeOfComponent === 'MaskPreview'
      ? <Overlay typeOfComponent='MaskPreview' size='img' hideOverlay={this.hideOverlay.bind(this)} maskData={this.state.maskData} maskPreviewIndex={this.state.maskPreviewIndex} />
      : this.state.typeOfComponent === 'EducatorDetail'
        ? <Overlay typeOfComponent='EducatorDetail' size='md' hideOverlay={this.hideOverlay.bind(this)} educatorId={this.state.educatorId} educatorName={this.state.educatorName} educatorFlag={this.state.educatorFlag} educatorDetailCompleteList={this.state.educatorDetailCompleteList}/>
        : ''
    return (
      <div id='admin-gallery'>
        {componentOverlay}
        <Header loggedIn={this.state.loggedIn} page={this.state.page} />
        <div>
          <div className='gallery-inner-left-blk'>

            <div className='gallery-outer-blk noselect'>
              <div className='gallery-inner-blk'>
                <div className='gallery-dashboard-filter'>
                  <div className='gallery-dashboard-filter-dash'> <b>DASHBOARD</b></div>
                  <div className={`gallery-dashboard-filter-today ${this.state.filterActive === 'today' ? 'active' : ''}`} onClick={() => this.handeleDateviceFilter('today')}>Today</div>
                  <div className={`gallery-dashboard-filter-week ${this.state.filterActive === 'this-week' ? 'active' : ''}`} onClick={() => this.handeleDateviceFilter('this-week')}>This Week</div>
                  <div className={`gallery-dashboard-filter-month ${this.state.filterActive === 'this-month' ? 'active' : ''}`} onClick={() => this.handeleDateviceFilter('this-month')}>This Month</div>
                  <div className={`gallery-dashboard-filter-year ${this.state.filterActive === 'this-year' ? 'active' : ''}`} onClick={() => this.handeleDateviceFilter('this-year')}>This Year</div>
                  <div className='gallery-dashboard-filter-cus'>
                    <div onClick={this.handleDateOpen}>{this.state.dateText}</div>
                    <div className={`DatePicker-blk ${this.state.showDate ? 'show' : 'hide'}`} ref={this.wrapperRef}>
                      <DateRange
                        editableDateInputs
                        moveRangeOnFirstSelection={false}
                        ranges={[this.state.selectionRange]}
                        onChange={this.handleDateSelection}
                      />
                    </div>
                  </div>
                </div>

                <div className='gallery-top-blk'>
                  <div className='gallery-top-row'>
                    <span style={{ display: 'none' }}>{JSON.stringify(this.state.selectedOptions)}</span>
                    <div className='filter-outer-blk'>
                      <div className='filter-inner-blk'>
                        <div className={'filter-blk ' + this.state.page}>
                          <label className='filter-title'>filter by</label>
                          <div className='hr-line' />
                          {/* <select onChange={this.handleFilterSelectChange} name='teacherCode' id='teacherCode'>
                            <option value=''>Your Code</option>
                            {this.state.teacherCodeList.map((item, index) => (
                              <option key={index} value={item}>{item}</option>
                            ))}
                          </select> */}
                          <input type='text' onChange={this.handleFilterInputChange} value={this.state.age} name='age' placeholder='Age' id='age' />
                          <select onChange={this.handleFilterSelectChange} name='gender' id='gender'>
                            <option value=''>Gender</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Other'>Other</option>
                            <option value='Prefer not to answer'>Prefer not to answer</option>
                          </select>
                          <input type='text' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.location} name='location' placeholder='City' id='location' />
                        </div>
                        <div className='search-blk'>
                          <img src={process.env.PUBLIC_URL + '/assets/images/icons/Search-small.png'} alt='Search-Icon' />
                          <input type='text' placeholder='Search with Teacher’s code or any keywords ' name='word' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.word} />
                        </div>
                      </div>
                    </div>
                    <div className='clr' />
                  </div>

                  <Reveal effect='fadeInUp'>
                    <div className={this.state.selectedOptions.length ? 'gallery-bottom-row' : 'gallery-bottom-row hide'}>
                      <p className='result-found'>{this.state.maskData.length} search results for</p>
                      <div className='selected-filter-blk'>
                        {this.state.selectedFilterValues.map((list, index) => {
                          return (
                            <Reveal effect='fadeInUp' key={index}>
                              <div className='selected-filter-option'>
                                <span className='label'>{list.name}</span>
                                <span>{list.value}</span>
                                <img className='close-img' src={process.env.PUBLIC_URL + '/assets/images/icons/Close.png'} alt='close' onClick={() => this.handleRemoveSpecificFilter(list.name)} />
                              </div>
                            </Reveal>
                          )
                        })}
                      </div>
                      <div className='clear-filter' onClick={this.handleFilterClear}>Clear All</div>
                    </div>
                  </Reveal>
                </div>
                <div className='gallery-content-outer'>
                  <div className='gallery-content-blk'>
                    {this.state.maskData.map((list, index) => {
                      return (<div className='mask-blk' key={index}>
                        <div className='mask-top'>
                          {/* checkbox */}
                          <div className='position-align'>
                            <div className='select-and-share-blk'>
                              <div className='round'>
                                <input type='checkbox' className='checkMask' id={'checkbox-' + index} data-id={list.maskId} data-index={index} name={'checkboxId-' + index} onChange={this.handleCheckboxChange} />
                                <label htmlFor={'checkbox-' + index} />
                              </div>
                            </div>
                          </div>
                          <img src={'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + list.maskGuid + '.png'} alt='mask' onClick={() => this.handleMaskPreview(index)} />
                        </div>
                        <div className='mask-bottom'>
                          <div className='mask-info'><span>{list.age} Yrs</span> | <span title={list.gender.length > 15 ? list.gender : ''}>{list.gender.length > 15 ? list.gender.substring(0, 15) + '...' : list.gender}</span> | <span style={{ textTransform: 'capitalize' }} title={list.city.length > 15 ? list.city : ''}>{list.city.length > 15 ? list.city.substring(0, 15) + '...' : list.city}</span></div>
                          {/* download */}
                          <div onClick={() => this.handleSingleDownload(list.maskGuid)}><img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-plain.png'} alt='download' /></div>
                        </div>
                      </div>)
                    })}
                    {this.state.maskData.length === 0 ? <div><h1 style={{ textAlign: 'center', color: '#FFF' }}>No Mask Found</h1></div> : ''}
                    <div className='pagination-outer'>
                      {this.state.isNextPageAvailable
                        ? (<div className='pagination-inner'>
                          <div className='next-blk' onClick={() => this.handleNextPrev('N')}><p>Show more</p></div>
                        </div>)
                        : ''}
                    </div>
                  </div>
                </div>
                <div className='footer'>
                  <p>© everforwardclub.org - 2020</p>
                </div>
                <div className={this.state.showDownload ? 'showdownload-outer show' : 'showdownload-outer hide'}>
                  <div className='showdownload-inner'>
                    <span className='selected-count-title'>{this.state.checkedMask.length} Mask{this.state.checkedMask.length > 1 ? 's' : ''} Selected</span>
                    <div className='hr-line' />
                    <span className='selected-title'>Download selected Mask{this.state.checkedMask.length > 1 ? 's' : ''}</span>
                    <div className='download-btn' onClick={this.handleMultipleDownload}>
                      <img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-solid.png'} alt='download' />
                      <span className='download-label'>Download</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='gallery-inner-right-blk'>
            <div>
              <div className='gallery-inner-right-blk-topdiv'>
                <div className='gallery-inner-right-blk-topdiv-lfloat'><b>EDUCATORS</b></div>
                <div className='gallery-inner-right-blk-topdiv-rfloat' onClick={this.handleAllEducator}>See All</div>
              </div>
              <table className='gallery-inner-right-blk-topdiv-table'>
                <thead className='gallery-inner-right-blk-topdiv-table-head'>
                  <tr>
                    <td className='gallery-inner-right-blk-topdiv-table-head-cell'>
                      <div className='gallery-inner-right-blk-topdiv-name-rfloat'>Name</div>
                      <div className='gallery-inner-right-blk-topdiv-code-rfloat'>No.of.Codes &amp; Masks</div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {this.state.educatorDetail.map((namecode, index) => {
                        return (<div key={index} className='gallery-inner-right-blk-topdiv-table-head-cell' onClick={() => this.handleOpenEducator(namecode.email, namecode.name)}>
                          <div className='gallery-inner-right-blk-topdiv-name-rfloat'>{namecode.name}</div>
                          <div className='gallery-inner-right-blk-topdiv-code-rfloat'>{namecode.codesCount} ({namecode.maskCount})</div>
                          <br />
                        </div>
                        )
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div className='gallery-inner-right-blk-topdiv'>
                <div className='gallery-inner-right-blk-topdiv-lfloat'><b>PHRASES</b></div>
                <a href='/' className='gallery-inner-right-blk-topdiv-rfloat'>Download All</a>
              </div>
              <div>
                <div className={`gallery-dashboard-filter-front ${this.state.phraseActive === 'FT' ? 'active' : ''}`} onClick={() => this.handlePhrasesDetails('FT')}>Front</div>
                <div className={`gallery-dashboard-filter-back  ${this.state.phraseActive === 'BT' ? 'active' : ''}`} onClick={() => this.handlePhrasesDetails('BT')}>Back</div>
              </div>
              <table className='gallery-inner-right-blk-topdiv-table'>
                <thead className='gallery-inner-right-blk-topdiv-table-head'>
                  <tr>
                    <td className='gallery-inner-right-blk-topdiv-table-head-cell'>
                      <div className='gallery-inner-right-blk-topdiv-name-rfloat'>Top Phrases</div>
                      <div className='gallery-inner-right-blk-topdiv-code-rfloat'>No.Masks</div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {(this.state.phraseList).map((key, i) => (<div key={i} className='gallery-inner-right-blk-topdiv-table-head-cell'>
                        <div className='gallery-inner-right-blk-topdiv-phrase-name-rfloat'>{key[0]}</div>
                        <div className='gallery-inner-right-blk-topdiv-phrase-code-rfloat'>{key[1]} </div>
                        <br />
                      </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>
    )
  }
}

export default AdminDashboard
