import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// components
import Landing from './components/Landing/Landing'
import DrawBoard from './components/DrawBoard/DrawBoard'
import Gallery from './components/Gallery/Gallery'
import AdminDashboard from './components/AdminDashboard/AdminDashboard'
import TeacherDashboard from './components/TeacherDashboard/TeacherDashboard'
import Authentication from './components/Authentication/Authentication'
import TeacherOnBoardSuccess from './components/TeacherOnBoardSuccess/TeacherOnBoardSuccess'
import NotFound from './components/NotFound/NotFound'
import Download from './components/Download/Download'

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/auth/:role/:componentName' component={Authentication} />
          <Route exact path='/auth/:role/:componentName' component={Authentication} />
          <Route exact path='/:componentName/:role/:encodedString' component={Authentication} />

          <Route exact path='/gallery' component={Gallery} />
          <Route exact path='/teacher/dashboard' component={TeacherDashboard} />
          <Route path='/admin/dashboard' component={AdminDashboard} />

          <Route exact path='/teacheronboardsuccess' component={TeacherOnBoardSuccess} />
          <Route exact path='/drawboard' component={DrawBoard} />
          <Route path='/teacherrefer/:id' component={DrawBoard} />

          <Route path='/admin/download' component={Download} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
