import React, { Component } from 'react'
 import { Redirect } from 'react-router-dom'
// components
import SignUpForm from './SignUpForm/SignUpForm'
import SignInForm from './SignInForm/SignInForm'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ResetPassword from './ResetPassword/ResetPassword'
// css
import './Authentication.css'

class Authentication extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false,
      redirectTarget: ''
    }
    this.onHandleSignUp = this.onHandleSignUp.bind(this)
    this.onHandleSignIn = this.onHandleSignIn.bind(this)
    this.onHandleRedirectToSignin = this.onHandleRedirectToSignin.bind(this)
  }

  onHandleSignUp (flag) {
    if (flag === 'SUCCESS') {
      this.setState({ redirect: true, redirectTarget: '/teacheronboardsuccess' })
    }
  }

  onHandleSignIn (flag, role) {
    if (flag === 'SUCCESS') {
      this.setState({ redirect: true, redirectTarget: role === 'teacher' ? '/teacher/dashboard' : '/admin/dashboard' })
    }
  }

  onHandleRedirectToSignin (flag, role) {
    if (flag === 'SUCCESS') {
      this.setState({ redirect: true, redirectTarget: role === 'teacher' ? '/auth/teacher/signin' : '/auth/admin/signin' })
    }
  }

   componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    console.log('loginCheck', loginCheck)
    if (loginCheck) {
      if (loginCheck.role) this.setState({ redirect: true, redirectTarget: '/admin/dashboard' })
      else this.setState({ redirect: true, redirectTarget: '/teacher/dashboard' })
    }
  }

  render () {
   
    if (this.state.redirect) {
      this.setState({ redirect: false})
     return <Redirect to={this.state.redirectTarget} />
    }

    return (
      <div id='teacher-on-board'>
        <div id='outer-blk' className={this.state.signUpSuccess ? 'success' : ''}>
          <div className='logo-blk'>
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} className='logo-img' alt='logo' />
            </a>
            <div className='vr-line' />
            {this.props.match.params.role === 'teacher'
              ? <a href='/'>
                <img src={process.env.PUBLIC_URL + '/assets/images/ic_everforward.png'} className='ever-img' alt='logo' />
              </a>
              : <p className='admin-console'>Admin console</p>
            }
          </div>
          <div id='right-side-blk'>
            {this.props.match.params.componentName === 'signup'
              ? <SignUpForm handleSignUp={this.onHandleSignUp} role={this.props.match.params.role} />
              : this.props.match.params.componentName === 'signin'
                ? <SignInForm handleSignIn={this.onHandleSignIn} role={this.props.match.params.role} />
                : this.props.match.params.componentName === 'forgotpassword'
                  ? <ForgotPassword handleRedirectToSignin={this.onHandleRedirectToSignin} role={this.props.match.params.role} />
                  : this.props.match.params.componentName === 'resetPassword'
                    ? <ResetPassword handleRedirectToSignin={this.onHandleRedirectToSignin} role={this.props.match.params.role} encodedString={this.props.match.params.encodedString} />
                    : ''
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Authentication
