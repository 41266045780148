import React, { Component } from 'react'
import agent from '../../api/Api'
import $ from 'jquery'
// import { saveAs } from 'file-saver'
// import * as JSZip from 'jszip'
// import * as JSZipUtils from 'jszip-utils'
import { CSVLink } from 'react-csv'
import './Download.css'
import Header from './DownloadHeader/DownloadHeader'

class Download extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedOptions: [],
      word: '',
      maskData: [],
      isNextPageAvailable: false,
      csvMaskteachersData: [],
      csvMaskData: [],
      csvAllMaskData: [],
      noMaskText: false,
      warningText: false,
      taskId: null,
      downloadMsg: ''
    }
    this.handleFocus = this.handleFocus.bind(this)
    this.handleGetMask = this.handleGetMask.bind(this)
    this.handleDownloadAllMaskDetails = this.handleDownloadAllMaskDetails.bind(this)
    this.handleDownloadAllMaskImage = this.handleDownloadAllMaskImage.bind(this)
    this.handleSynLastImage = this.handleSynLastImage.bind(this)
    this.handleCheckSynStatus = this.handleCheckSynStatus.bind(this)
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this)
  }

  componentDidMount () {
    agent.APILIST.getMask('/teacher/users/list')
      .then(res => {
        if (res.status === 'SUCCESS') {
          let reframe = []
          res.data.forEach(list => {
            list.teacherCode = '=""'+ list.teacherCode +'""'
            reframe.push(list)
          });
          // console.log(reframe)
          this.setState({
            // csvMaskteachersData: res.data
            csvMaskteachersData: reframe
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleFilterInputChange (event) {
    const { name, value } = event.target
    if (name === 'word') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value, noMaskText: false, csvMaskData: [], maskData: [], warningText: false })
      }
    }
  }

  handleGetMask () {
    if (this.state.word) {
      $('#loader').fadeIn()
      let param = ''
      if (this.state.isNextPageAvailable) {
        param += 'cursor=' + this.state.nextCursor + '&&'
      }
      param += 'word=' + this.state.word

      if (param) {
        param = '?' + param
      }

      agent.APILIST.getMask('/mask/gallery' + param)
        .then(res => {
          if (res.status === 'SUCCESS') {
            if (res.data.isNextPageAvailable) {
              this.setState({
                maskData: this.state.maskData.concat(res.data.maskDetails),
                nextCursor: res.data.nextCursor,
                isNextPageAvailable: res.data.isNextPageAvailable
              })
              this.handleGetMask()
            } else {
              this.setState({
                maskData: this.state.maskData.concat(res.data.maskDetails),
                nextCursor: res.data.nextCursor,
                isNextPageAvailable: res.data.isNextPageAvailable
              })
              this.getMaskCSV('')
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else this.setState({ warningText: true })
  }

  handleDownloadAllMaskDetails (flag) {
    $('#loader').fadeIn()
    let param = ''
    if (this.state.isNextPageAvailable) {
      param += 'cursor=' + this.state.nextCursor
    }
    if (param) {
      param = '?' + param
    }

    agent.APILIST.getMask('/mask/gallery' + param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (res.data.isNextPageAvailable) {
            this.setState({
              maskData: this.state.maskData.concat(res.data.maskDetails),
              nextCursor: res.data.nextCursor,
              isNextPageAvailable: res.data.isNextPageAvailable
            })
            this.handleDownloadAllMaskDetails(flag)
          } else {
            this.setState({
              maskData: this.state.maskData.concat(res.data.maskDetails),
              nextCursor: res.data.nextCursor,
              isNextPageAvailable: res.data.isNextPageAvailable
            })

            // if (flag === 'IMG') this.handleDownloadAllMaskImage(flag)
            // else if (flag === 'CSV') this.getMaskCSV('ALL')
            this.getMaskCSV('ALL')
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleCheckSynStatus (task) {
    setInterval(() => {
      agent.APILIST.checkSync('/mask/status/' + task)
        .then(res => {
          if (res.status === 'SUCCESS') {
            if (res.taskStatus === 'completed') {
              this.setState({ taskId: null })
              var temp = 'Sync completed. You can download now'
              this.setState({ downloadMsg: temp })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }, 5000)
  }

  handleSynLastImage () {
    agent.APILIST.startSync('/mask/syncMaskImages')
      .then(res => {
        if (res.status === 'SUCCESS') {
          this.setState({ downloadMsg: 'Sync image progress.....' })
          this.setState({ taskId: res.taskId })
          this.handleCheckSynStatus(res.taskId)
        } else {
          this.setState({ taskId: null })
          this.setState({ downloadMsg: 'Sync image progress.....' })
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({ taskId: null })
      })
  }

  handleDownloadAllMaskImage () {
    $('#loader').fadeIn()
    this.setState({ downloadMsg: '' })
    agent.APILIST.getDownloadURL('/mask/signedUrlImagesZipFiles')
      .then(res => {
        if (res.status === 'SUCCESS') {
          var temp = 'Images last synced date: ' + res.lastUpdatedDate
          this.setState({ downloadMsg: temp })
          var a = document.createElement('a')
          document.body.appendChild(a)
          var url = res.data.signedUrl
          a.rel = 'nofollow'
          a.href = url
          a.click()
          window.URL.revokeObjectURL(url)
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err)
      })
  }

  // handleDownloadAllMaskImage () {
  //   var zip = new JSZip()
  //   var count = 0
  //   var zipFilename = 'maskImages.zip'
  //   var maskData = JSON.parse(JSON.stringify(this.state.maskData))
  //   var total = maskData.length
  //   console.log(total)
  //   maskData.forEach(function (item) {
  //     var imgUrl = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + item.maskGuid + '.png'
  //     // loading a file and add it in a zip file
  //     JSZipUtils.getBinaryContent(imgUrl, function (err, data) {
  //       // if (err) {
  //       //   throw err // or handle the error
  //       // }
  //       if (data) {
  //         zip.file(imgUrl, data, { binary: true })
  //       }
  //       count++
  //       // console.log(count)
  //       if (count === maskData.length) {
  //         zip.generateAsync({ type: 'blob' }).then(function (content) {
  //           saveAs(content, zipFilename)
  //           $('#loader').fadeOut()
  //         })
  //       }
  //     })
  //   })
  // }

  getMaskCSV (flag) {
    // console.log(this.state.maskData)
    $('#loader').fadeOut()
    if (this.state.maskData.length) {
      const csvArray = []
      for (let i = 0; i < this.state.maskData.length; i++) {
        let obj = {}
        // console.log('front', this.state.maskData[i].frontMaskTags)
        // console.log('back', this.state.maskData[i].backMaskTags)
        obj.maskGuid = this.state.maskData[i].maskGuid
        obj.city = this.state.maskData[i].city
        obj.src = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + this.state.maskData[i].maskGuid + '.png'
        obj.frontMaskTags = this.state.maskData[i].frontMaskTags ? this.state.maskData[i].frontMaskTags.join(", ") : ''
        obj.backMaskTags = this.state.maskData[i].backMaskTags ? this.state.maskData[i].backMaskTags.join(", ") : ''
        csvArray.push(obj)
      }
      // console.log(csvArray)
      if (flag) {
        this.setState({ csvAllMaskData: csvArray, word: '' }, () => {
          this.csvAllLink.link.click()
        })
        setTimeout(() => this.setState({ maskData: [] }), 1000)
      } else {
        this.setState({ csvMaskData: csvArray, word: '' }, () => {
          this.csvLink.link.click()
        })
        setTimeout(() => this.setState({ maskData: [] }), 1000)
      }
    } else this.setState({ noMaskText: true })
  }

  handleFocus () {
    $('#search-input').focus()
  }

  render () {
    return (
      <div id='download'>
        <div className='showdownload-outer show' /> {/* : 'showdownload-outer hide'}/ >} */}
        <Header />
        <div className='download-outer-blk noselect'>
          <div className='download-inner-blk'>
            <div className='download-top-blk'>
              <div className='download-top-row'>
                <div className='title-blk'>Download Teacher's List</div>
                <CSVLink filename='teacherdeatils.csv' data={this.state.csvMaskteachersData} className='drawmask-btn-section'>
                  <div className='draw-btn'>
                    <p>Download</p>
                  </div>
                </CSVLink>
              </div>
            </div>
            <div className='div-line' />
            <div className='download-top-blk'>
              <div className='download-top-row'>
                <div className='title-blk'>Download All Mask Details</div>
                <div onClick={() => this.handleDownloadAllMaskDetails('CSV')} className='drawmask-btn-section'>
                  <div className='draw-btn'>
                    <p>Download</p>
                  </div>
                </div>
                <CSVLink
                  data={this.state.csvAllMaskData}
                  filename="allmask.csv"
                  className="hidden"
                  ref={(r) => this.csvAllLink = r}
                  target="_blank"/>
              </div>
            </div>
            <div className='div-line' />
            <div className='download-top-blk'>
              <div className='download-top-row'>
                <div className='title-blk'>Download All Mask Image</div>
                <div className='drawmask-btn-section'>
                  <div className={`draw-btn ${this.state.taskId ? 'disable' : ''}`}>
                    <p onClick={() => this.handleDownloadAllMaskImage()}>Download</p>
                  </div>
                </div>
                <div className='drawmask-btn-section'>
                  <div className={`draw-btn ${this.state.taskId ? 'disable' : ''}`}>
                    <p onClick={() => this.handleSynLastImage()}>SYNC IMAGES</p>
                  </div>
                </div>
              </div>
              {this.state.downloadMsg
                ? <div className='download-top-row'>
                  <p style={{ color: 'white' }}>{this.state.downloadMsg}</p>
                </div>
                : ''
              }
            </div>
            <div className='div-line' />
            <div className='download-bottom-blk'>
              <div className='download-bottom-row'>
                <div className='title-blk'>Download your Mask</div>
                <div className='filter-outer-blk'>
                  <div className='filter-inner-blk'>
                    <div className='search-blk'>
                      <img src={process.env.PUBLIC_URL + '/assets/images/icons/Search-small.png'} alt='Search-Icon' onClick={this.handleFocus} />
                      <input id='search-input' type='text' placeholder='Search with Teacher’s code or any keywords ' name='word' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.word} />
                    </div>
                  </div>
                </div>
                <div onClick={this.handleGetMask} className='drawmask-btn-section'>
                  <div className='draw-btn'>
                    <p>Download</p>
                  </div>
                </div>
                <CSVLink
                  data={this.state.csvMaskData}
                  filename="specificmask.csv"
                  className="hidden"
                  ref={(r) => this.csvLink = r}
                  target="_blank"/>
              </div>
            </div>
            {this.state.warningText ? <span className='warning-text'>Keyword is mandatory</span> : null}
            {this.state.noMaskText ? <div><h3 style={{ textAlign: 'center', color: '#FFF' }}>No Mask Found</h3></div> : ''}
          </div>
        </div>
      </div>
    )
  }
}

export default Download
