import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Overlay from '../Overlay/Overlay'
// css
import './Header.css'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: { name: '' },
      redirect: false,
      redirectTarget: '',
      typeOfComponent: '',
      param: ''
    }
    this.handleMyCodes = this.handleMyCodes.bind(this)
    this.handleEditProfile = this.handleEditProfile.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  hideOverlay (flag, param) {
    this.setState({ typeOfComponent: '' })
  }

  handleLogout () {
    localStorage.removeItem('userInfo')
    this.setState({ redirect: true, redirectTarget: '/' })
  }

  handleMyCodes () {
    this.setState({ typeOfComponent: 'MyCodes' })
  }

  handleEditProfile () {
    this.setState({ typeOfComponent: 'EditProfile' })
  }

  handleChangePassword () {
    this.setState({ typeOfComponent: 'ChangePassword' })
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    if (loginCheck) {
      this.setState({ userInfo: loginCheck })
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTarget} />
    }
    const componentOverlay = this.state.typeOfComponent === 'EditProfile'
      ? <Overlay typeOfComponent='EditProfile' size='lg' param={this.state.param} hideOverlay={this.hideOverlay.bind(this)} />
      : this.state.typeOfComponent === 'ChangePassword'
        ? <Overlay typeOfComponent='ChangePassword' size='md' param={this.state.param} hideOverlay={this.hideOverlay.bind(this)} />
        : this.state.typeOfComponent === 'MyCodes'
          ? <Overlay typeOfComponent='MyCodes' size='md' param={this.state.param} hideOverlay={this.hideOverlay.bind(this)} />
          : ''

    return (
      <div id='header'>
        {componentOverlay}
        <div className='header-outer-blk noselect'>
          <div className='gallery-logo-blk'>
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} className='gallery-logo-img' alt='logo' />
            </a>
            {this.props.role === 'admin'
              ? <p className='admin-console'>Admin console</p>
              : <>
                <div className='vr-line' />
                <a href='/'>
                  <img src={process.env.PUBLIC_URL + '/assets/images/ic_everforward.png'} className='ever-img' alt='logo' />
                </a>
              </>
            }
          </div>
          <div className='header-right-btn-section'>
            <div className='d-flex'>
              {this.props.role === 'gallery'
                ? <a className='draw-btn' href='/drawboard'>
                  <img className='mask-img' src={process.env.PUBLIC_URL + 'assets/images/icons/mask.png'} alt='mask' />
                  <p>draw your mask </p>
                </a>
                : <div />
              }
              {this.props.loggedIn
                ? <div className='dropdown'>
                  <button className='dropbtn'>{this.state.userInfo.name || this.state.userInfo.firstName} <img src={process.env.PUBLIC_URL + 'assets/images/icons/arrow-white-down.svg'} alt='down' /> </button>
                  <div className='dropdown-content'>
                    <p onClick={this.handleMyCodes}>My Codes</p>
                    <p onClick={this.handleEditProfile}>Profile</p>
                    <p onClick={this.handleChangePassword}>Change password</p>
                    <p onClick={this.handleLogout} style={{ color: '#E02020' }}>Signout</p>
                  </div>
                </div>
                : ''
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
