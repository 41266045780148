import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
import { Beforeunload } from 'react-beforeunload'
import cookie from 'react-cookies'
// component
import Toaster from '../Toaster/Toaster'
import Overlay from '../Overlay/Overlay'
import Header from './DrawHeader/DrawHeader'
import TakeTour from './TakeTour/TakeTour'
// css
import './DrawBoard.css'
class DrawBoard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      typeOfComponent: '',
      procedure: [{ number: 1,
        desc: 'Draw a mask on the left side.'
      }, {
        number: 2,
        desc: 'Write 3 words on the Front of Mask that represent qualities you let people see'
      }, {
        number: 3,
        desc: 'Write 3 words on the Back of Mask that represent the things you don\'t usually let people see.'
      }, {
        number: 4,
        desc: 'Click Submit'
      }],
      isSidebar: false,
      imageData: '',
      teacherCode: '',
      frontTag: '',
      backTag: '',
      height: '',
      param: '',
      fTag1: '',
      fTag2: '',
      fTag3: '',
      bTag1: '',
      bTag2: '',
      bTag3: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDrawPreview = this.handleDrawPreview.bind(this)
    this.drawboardInit = this.drawboardInit.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    this.startTour = this.startTour.bind(this)
    this.closeTour = this.closeTour.bind(this)
    this.handleOpenCloseNav = this.handleOpenCloseNav.bind(this)
    this.handlekeyEvent = this.handlekeyEvent.bind(this)
  }

  componentWillMount () {
    let plugin = ['assets/lib/main.js']
    var loadScript = function (src) {
      var tag = document.createElement('script')
      tag.async = false
      tag.src = src
      document.getElementsByTagName('head')[0].appendChild(tag)
    }

    for (let index = 0; index < plugin.length; index++) {
      loadScript(plugin[index])
    }
  }

  componentDidMount () {
    this.drawboardInit()
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('keydown', this.handlekeyEvent, false)
    let cookieCheck = cookie.load('skip')
    if (!cookieCheck) {
      var now = new Date()
      // now.setTime(now.getTime() + 1 * 3600 * 1000); // one hour
      now.setDate(now.getDate() + 1) // one day
      cookie.save('skip', 'true', { expires: now })
      this.setState({typeOfComponent: 'TakeTour'})
    }
    console.log('cookies', cookieCheck)
    this.setState({teacherCode: this.props.match.params.id})
  }

  drawWaterMark (URL) {
    let canvas = document.getElementById('canvasInAPerfectWorld')
    let context = canvas.getContext('2d')
    var image = new Image()
    image.src = URL
    var x = image.onload = (function () {
      context.drawImage(image, canvas.width / 2 - image.width / 2, canvas.height / 2 - image.height / 2)
      return true
    }())
    return x
  }

  handleResize () {
    this.drawboardInit()
  }

  drawboardInit () {
    let h = 0
    if ($(window).width() <= 480) {
      h = $(window).height() - 220
    } else {
      h = $(window).height() - 160
    }

    this.setState({height: h})

    // new
    let newHeight = 0
    let newWidth = 0
    // canvas
    let orgWidth = 1024
    let orgHeight = 500
    // device
    let devWidth = $(window).width()
    let devHeight = $(window).height()

    if (devWidth >= 1024) {
      newWidth = orgWidth
      newHeight = orgHeight
    } else if (devWidth < 1024 && devWidth >= 768) {
      newWidth = 768
      newHeight = 375
    } else {
      newWidth = 410
      newHeight = 200
    }

    $('#vr-line').css({height: newHeight - 25})

    let canvas = document.getElementById('canvasInAPerfectWorld')
    let context = canvas.getContext('2d')
    canvas.width = newWidth
    canvas.height = newHeight

    // set canvas background color
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    let labelTop = 0
    let backLabelLeft = 0

    if ($(window).width() < 768) {
      // mobile
      labelTop = ((devHeight - newHeight - 120) / 2) + 20
      backLabelLeft = ((devWidth - newWidth) / 2) + ((newWidth / 3) * 2) + 20
    } else {
      // others
      labelTop = ((devHeight - newHeight - 60) / 2) + 20
      backLabelLeft = ((devWidth - newWidth) / 2) + ((newWidth / 3) * 2) + 80
    }
    let frontLabelLeft = ((devWidth - newWidth) / 2) + (newWidth / 3) - 80
    let splitLine = ((devWidth - newWidth) / 2) + (newWidth / 3) * 2
    $('.front-name-blk').css({ top: labelTop, left: frontLabelLeft })
    $('.back-name-blk').css({ top: labelTop, left: backLabelLeft })
    $('#vr-line').css({ top: labelTop, left: splitLine })

    if ($(window).width() <= 480) {
      document.getElementById('mySidebar').style.left = '-30%'
    } else {
      document.getElementById('mySidebar').style.left = '-21%'
    }
  }

  handleInputChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleDrawPreview (param) {
    this.setState({ typeOfComponent: 'DrawPreview', param: param })
  }

  handlekeyEvent (event) {
    // console.log(event.keyCode, event.which)
    if (this.state.typeOfComponent === 'TakeTour' && (event.keyCode === 9 || event.which === 9)) {
      event.preventDefault()
    }
  }

  hideOverlay (flag, param) {
    if (flag === 'SUBSCRIBE') {
      this.setState({ typeOfComponent: 'Subscribe', param: param })
    } else if (flag === 'THANKS') {
      this.setState({ typeOfComponent: 'MaskSuccess', param: param })
    } else if (flag === 'DRAWPREVIEW') {
      this.setState({ typeOfComponent: 'DrawPreview', param: param })
    } else {
      this.setState({ typeOfComponent: '' })
    }

    if (flag === 'BOARD') {
      let canvas1 = document.getElementById('canvasInAPerfectWorld1')
      let context1 = canvas1.getContext('2d')
      var image = new Image()
      image.src = param.originalMask
      context1.globalAlpha = 1
      image.onload = () => {
        $('.front-name-blk').show()
        $('.back-name-blk').show()
        $('.vr-line').show()
        context1.drawImage(image, canvas1.width / 2 - image.width / 2, canvas1.height / 2 - image.height / 2)
      }
    }
  }

  closeTour () {
    this.setState({ typeOfComponent: '' })
  }

  startTour () {
    this.setState({ typeOfComponent: 'TakeTour' })
  }

  handleSubmit () {
    let canvas = document.getElementById('canvasInAPerfectWorld1')
    let context = canvas.getContext('2d')

    // tags should not be empty
    if (this.state.fTag1.trim() || this.state.fTag2.trim() || this.state.fTag3.trim() || this.state.bTag1.trim() || this.state.bTag2.trim() || this.state.bTag3.trim()) {
      let originalMask = canvas.toDataURL('image/jpg', 1.0)

      $('.front-name-blk').hide()
      $('.back-name-blk').hide()
      $('.vr-line').hide()

      // mask front and back lable
      let frontpoint = (canvas.width / 4)
      let backpoint = (canvas.width / 4 * 3)

      // draw board side label
      context.fillStyle = '#000'
      let frontTitle = 'FRONT OF THE MASK'
      let frontSubTitle = '(Drawings + Words)'
      let backTitle = 'BACK OF THE MASK'
      let backSubTitle = '(Words Only)'

      context.font = 'bold 15px Arial'
      context.fillText(frontTitle, frontpoint, 30)
      context.fillText(backTitle, backpoint, 30)
      context.font = '12px Arial'
      context.fillText(frontSubTitle, frontpoint + 30, 45)
      context.fillText(backSubTitle, backpoint + 40, 45)

      // draw vertical line between canvas
      let linePoint = (canvas.width / 3) * 2
      context.beginPath()
      context.moveTo(linePoint, 0)
      context.lineTo(linePoint, canvas.height)
      context.strokeStyle = '#DDD'
      context.lineWidth = 1
      context.stroke()

      let frontTagArr = []
      let backTagArr = []

      if (this.state.fTag1.trim()) frontTagArr.push(this.state.fTag1.toLowerCase().trim())
      if (this.state.fTag2.trim()) frontTagArr.push(this.state.fTag2.toLowerCase().trim())
      if (this.state.fTag3.trim()) frontTagArr.push(this.state.fTag3.toLowerCase().trim())

      if (this.state.bTag1.trim()) backTagArr.push(this.state.bTag1.toLowerCase().trim())
      if (this.state.bTag2.trim()) backTagArr.push(this.state.bTag2.toLowerCase().trim())
      if (this.state.bTag3.trim()) backTagArr.push(this.state.bTag3.toLowerCase().trim())

      // add tags
      // let frontTag = ''
      // let backTag = ''

      // for (let i = 0; i < frontTagArr.length; i++) {
      //   frontTag += frontTagArr[i].charAt(0).toUpperCase() + frontTagArr[i].substring(1)
      //   if (i !== frontTagArr.length - 1) frontTag += ' | '
      // }

      // for (let i = 0; i < backTagArr.length; i++) {
      //   backTag += backTagArr[i].charAt(0).toUpperCase() + backTagArr[i].substring(1)
      //   if (i !== backTagArr.length - 1) backTag += ' | '
      // }

      // draw tags in canvas
      // context.fillStyle = '#0F0F0F'
      // context.font = '15px Arial'
      // context.fillText(frontTag, (linePoint / 2) - ((frontTag.length * 7) / 2), (canvas.height - 40))
      // if (backTag.length > 40) {
      //   context.fillText(backTag.substring(0, 40), (linePoint + 20), (canvas.height - 40))
      //   context.fillText(backTag.substring(40, backTag.length), (linePoint + 20), (canvas.height - 20))
      // } else {
      //   context.fillText(backTag, (linePoint + 40), (canvas.height - 40))
      // }

      // draw water mark
      var image = new Image()
      image.src = 'assets/images/waterMark.png'

      image.onload = () => {
        context.globalAlpha = 0.2
        let x = linePoint - image.width
        let y = canvas.height - image.height - 10
        context.drawImage(image, x, y)
        var param = {
          imageData: canvas.toDataURL('image/jpg', 1.0),
          originalMask: originalMask,
          frontMaskTags: frontTagArr,
          backMaskTags: backTagArr,
          teacherCode: this.state.teacherCode
        }
        this.handleDrawPreview(param)
      }
    } else {
      toast.dismiss()
      toast(<Toaster title='Error' content='Please fill atleast one tag for the mask' status='error' />)
      setTimeout(() => {
        toast.dismiss()
      }, 3000)
    }
  }

  handleOpenCloseNav () {
    // console.log(this.state.isSidebar)
    if (this.state.isSidebar) {
      this.setState({isSidebar: false})
      if ($(window).width() <= 480) {
        document.getElementById('mySidebar').style.left = '-30%'
      } else {
        document.getElementById('mySidebar').style.left = '-21%'
      }
    } else {
      this.setState({isSidebar: true})
      document.getElementById('mySidebar').style.left = '0'
    }
  }

  handlePageLoad (event) {
    let checkValue = $('.submit-mask').attr('class')
    if (checkValue.indexOf('enable') !== -1 && this.state.typeOfComponent !== 'MaskSuccess') {
      return 'There is pending work. Sure you want to leave?'
    }
  }

  render () {
    const componentOverlay = this.state.typeOfComponent === 'DrawPreview'
      ? <Overlay typeOfComponent='DrawPreview' size='lg' hideOverlay={this.hideOverlay.bind(this)} param={this.state.param} />
      : this.state.typeOfComponent === 'MaskPreview'
        ? <Overlay typeOfComponent='MaskPreview' size='lg' param={this.state.param} hideOverlay={this.hideOverlay.bind(this)} />
        : this.state.typeOfComponent === 'Subscribe'
          ? <Overlay typeOfComponent='Subscribe' size='md' param={this.state.param} hideOverlay={this.hideOverlay.bind(this)} />
          : this.state.typeOfComponent === 'MaskSuccess'
            ? <Overlay typeOfComponent='MaskSuccess' size='sm' hideOverlay={this.hideOverlay.bind(this)} param={this.state.param} />
            : this.state.typeOfComponent === 'TakeTour'
              ? <TakeTour closeTour={this.closeTour} />
              : ''

    return (
      <Beforeunload onBeforeunload={() => this.handlePageLoad()} >
        <div id='drawboard'>
          <div id='mySidebar' className='sidebar'>
            <div className='sidebar-outer'>
              <div className='sidebar-open-close-blk' onClick={this.handleOpenCloseNav} >
                { this.state.isSidebar
                  ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/arrow-red-left.png'} alt='Arrow' />
                  : <img src={process.env.PUBLIC_URL + '/assets/images/icons/arrow-red-right.png'} alt='Arrow' />
                }
              </div>
              <div className='steps-outer'>
                <div className='sidebar-title-blk noselect'><p className='title'>Instructions</p></div>
                { this.state.procedure.map((list, index) => {
                  return (<div className='step-blk noselect' key={index}>
                    <div className='step-number'><span>{list.number}</span></div>
                    <div className='step-desc'><p>{list.desc}</p></div>
                    {this.state.procedure.length - 1 !== index ? <div className='hr-line' /> : ''}
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='main-blk'>
            {componentOverlay}
            <Header save={this.handleSubmit} startTour={this.startTour.bind(this)} />
            <div className='drawboard-outer-blk noselect'>
              <div className='drawboard-header' />
              <div id='canvasblk' style={{height: this.state.height}}>
                <div className='canvas-outer'>
                  <canvas id='canvasInAPerfectWorld' width='1024' height='500' />
                  <canvas id='canvasInAPerfectWorld1' width='1024' height='500' style={{display: 'none'}} />
                  <span className='front-name-blk noselect'>
                    <p className='title'>FRONT OF THE MASK</p>
                    <p className='desc'>(Drawings + Words)</p>
                  </span>
                  <span className='back-name-blk noselect'>
                    <p className='title'>BACK OF THE MASK</p>
                    <p className='desc'>(Words Only)</p>
                  </span>
                  <div id='vr-line' />
                </div>
              </div>
              <div className='draw-footer-blk' id='draw-footer-blk'>
                <div className='draw-footer-outer'>
                  <div className='front-bottom'>
                    <div>
                      <p className='noselect'>What are the qualities and characteristics of yourself that you gladly let others see?</p>
                      <div className='input-blk'>
                        <input type='text' name='fTag1' id='fTag1' maxLength='25' placeholder='Type here' value={this.state.fTag1} onChange={this.handleInputChange} />
                        <input type='text' name='fTag2' id='fTag2' maxLength='25' placeholder='Type here' value={this.state.fTag2} onChange={this.handleInputChange} />
                        <input type='text' name='fTag3' id='fTag3' maxLength='25' placeholder='Type here' value={this.state.fTag3} onChange={this.handleInputChange} />
                      </div>
                    </div>
                  </div>
                  <div className='back-bottom'>
                    <div>
                      <p className='noselect'>What are the qualities and characteristics of yourself that you dont let others see?</p>
                      <div className='input-blk'>
                        <input type='text' name='bTag1' id='bTag1' maxLength='25' placeholder='Type here' value={this.state.bTag1} onChange={this.handleInputChange} />
                        <input type='text' name='bTag2' id='bTag2' maxLength='25' placeholder='Type here' value={this.state.bTag2} onChange={this.handleInputChange} />
                        <input type='text' name='bTag3' id='bTag3' maxLength='25' placeholder='Type here' value={this.state.bTag3} onChange={this.handleInputChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Beforeunload>
    )
  }
}

export default DrawBoard
